.SegmentedControlOption {
  height: 100%;
  border: 0;
  border-radius: inherit;
  background: transparent;
  color: var(--vkui--color_text_primary);
  transition: color 100ms ease-out;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
}

.SegmentedControlOption :global(.vkuiIcon) {
  color: var(--vkui--color_icon_primary);
}

.SegmentedControlOption:not(.SegmentedControlOption--checked):hover {
  opacity: 0.5;
}

.SegmentedControlOption__content {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  font-size: var(--vkui--font_text--font_size--regular);
  line-height: var(--vkui--font_text--line_height--regular);
  font-weight: var(--vkui--font_weight_accent2);
}

.SegmentedControlOption__content--sizeY-compact.SegmentedControlOption__content {
  font-size: var(--vkui--font_footnote--font_size--regular);
  line-height: var(--vkui--font_footnote--line_height--regular);
  font-weight: var(--vkui--font_weight_accent3);
}

@media (--sizeY-compact) {
  .SegmentedControlOption__content--sizeY-none.SegmentedControlOption__content {
    font-size: var(--vkui--font_footnote--font_size--regular);
    line-height: var(--vkui--font_footnote--line_height--regular);
    font-weight: var(--vkui--font_weight_accent3);
  }
}
