.View--ios {
}

.View--ios .Panel__in {
  padding-bottom: var(--vkui_internal--safe_area_inset_bottom);
}

.View--ios .Panel__in {
  padding: 0 var(--vkui_internal--safe_area_inset_right)
    var(--vkui_internal--safe_area_inset_bottom) var(--vkui_internal--safe_area_inset_left);
}

/**
 * Panel swipe back
 */

.View--ios .View__panel--swipe-back-prev {
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.3);
}

.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-success {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(100%, 0, 0);
}

.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-success {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(0, 0, 0);
}

.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-failed {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(0, 0, 0);
}

.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-failed {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(-50%, 0, 0);
}

/**
 * Panel transition
 */
.View--ios .View__panel--prev {
  animation: vkui-animation-ios-prev-forward 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next {
  animation: vkui-animation-ios-next-back 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--prev ~ .View__panel--next {
  animation: vkui-animation-ios-next-forward 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next ~ .View__panel--prev {
  animation: vkui-animation-ios-prev-back 0.6s var(--vkui--animation_easing_platform) forwards;
}

.View--ios .View__panel--prev :global(.vkuiInternalPanel__fade),
.View--ios .View__panel--next :global(.vkuiInternalPanel__fade) {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  pointer-events: none;
}

.View--ios .View__panel--prev :global(.vkuiInternalPanel__fade) {
  animation: vkui-animation-ios-fade-in 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next :global(.vkuiInternalPanel__fade) {
  animation: vkui-animation-ios-fade-out 0.6s var(--vkui--animation_easing_platform) forwards;
}

.View--ios .View__panel--prev ~ .View__panel--next :global(.vkuiInternalPanel__fade),
.View--ios .View__panel--next ~ .View__panel--prev :global(.vkuiInternalPanel__fade) {
  display: none;
}

.View--ios.View--no-motion .View__panel,
.View--ios.View--no-motion .View__panel .Panel__in {
  animation: none;
}

/**
 * Active panel
 */
.View--ios .View__panel--active :global(.vkuiInternalPanel__fade) {
  display: none;
}

@keyframes vkui-animation-ios-next-forward {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes vkui-animation-ios-next-back {
  from {
    transform: translate3d(-50%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes vkui-animation-ios-prev-forward {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes vkui-animation-ios-prev-back {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes vkui-animation-ios-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.3;
  }
}

@keyframes vkui-animation-ios-fade-out {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 0;
  }
}
