.InputLike__mask {
  position: relative;
  font-family: var(--vkui--font_family_base);
}

.InputLike__mask::after {
  content: '';
  position: absolute;
  width: calc(100% - 0.1em);
  height: 1px;
  bottom: 0;
  left: 0;
  border-bottom: solid 1px var(--vkui--color_text_secondary);
}

.InputLike__mask:last-child {
  margin-right: 1px;
}

.InputLike__last_character {
  letter-spacing: 1px !important;
}

.InputLike__divider {
  color: var(--vkui--color_text_secondary);
  letter-spacing: 1px !important;
}

.InputLike--full + .InputLike__divider {
  color: var(--vkui--color_text_primary);
}
