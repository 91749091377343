.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter__in {
  display: block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  box-sizing: border-box;
}

.Counter--mode-primary {
  background: var(--vkui--color_background_accent);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-secondary {
  background: var(--vkui--color_icon_secondary);
  color: var(--vkui--color_text_contrast_themed);
}

.Counter--mode-prominent {
  background: var(--vkui--color_accent_red);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-contrast {
  background: var(--vkui--color_background_content);
  color: var(--vkui--color_text_accent_themed);
}

.Counter--size-m {
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
}

.Counter--size-s {
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
}

.Counter--mode-inherit {
  background-color: var(
    --vkui_internal--counter_inherit_background,
    var(--vkui--color_icon_secondary)
  );
  color: var(--vkui_internal--counter_inherit_color, var(--vkui--color_text_contrast_themed));
}
