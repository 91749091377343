.Headline {
  display: block;
  margin: 0;
}

.Headline--level-1 {
  font-size: var(--vkui--font_headline1--font_size--regular);
  line-height: var(--vkui--font_headline1--line_height--regular);
  font-weight: var(--vkui--font_headline1--font_weight--regular);
  font-family: var(--vkui--font_headline1--font_family--regular);
}

.Headline--sizeY-compact.Headline--level-1 {
  font-size: var(--vkui--font_headline1--font_size--compact);
  line-height: var(--vkui--font_headline1--line_height--compact);
}

@media (--sizeY-compact) {
  .Headline--sizeY-none.Headline--level-1 {
    font-size: var(--vkui--font_headline1--font_size--compact);
    line-height: var(--vkui--font_headline1--line_height--compact);
  }
}

.Headline--level-2 {
  font-size: var(--vkui--font_headline2--font_size--regular);
  line-height: var(--vkui--font_headline2--line_height--regular);
  font-weight: var(--vkui--font_headline2--font_weight--regular);
  font-family: var(--vkui--font_headline2--font_family--regular);
}

.Headline--sizeY-compact.Headline--level-2 {
  font-size: var(--vkui--font_headline2--font_size--compact);
  line-height: var(--vkui--font_headline2--line_height--compact);
}

@media (--sizeY-compact) {
  .Headline--sizeY-none.Headline--level-2 {
    font-size: var(--vkui--font_headline2--font_size--compact);
    line-height: var(--vkui--font_headline2--line_height--compact);
  }
}

.Headline--weight-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Headline--weight-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Headline--weight-3 {
  font-weight: var(--vkui--font_weight_accent3);
}

/**
 * CMP:
 * PanelHeaderContent
 */
:global(.vkuiInternalPanelHeaderContent__children) .Headline {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
