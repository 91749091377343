.Group {
  color: var(--vkui--color_text_primary);
  padding-top: 8px;
  padding-bottom: 8px;
}

.Group__separator--spacing,
.Group__separator--separator {
  display: none;
}

.Group--sizeX-compact + .Group__separator--spacing + .Group__separator--separator,
.Group--mode-plain + .Group__separator--spacing + .Group__separator--separator {
  display: block;
}

@media (--sizeX-compact) {
  .Group--sizeX-none + .Group__separator--spacing + .Group__separator--separator {
    display: block;
  }
}

.Group--sizeX-regular.Group--mode-none + .Group__separator--spacing {
  display: block;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-none + .Group__separator--spacing {
    display: block;
  }
}

.Group--mode-card.Group--padding-s {
  padding: 4px;
}

.Group--mode-card.Group--padding-m {
  padding: 8px;
}

.Group--sizeX-compact {
  padding-left: 0;
  padding-right: 0;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--padding-s {
    padding: 4px;
  }

  .Group--sizeX-none.Group--padding-m {
    padding: 8px;
  }
}

.Group--mode-card {
  background: var(--vkui--color_background_content);
  border-radius: var(--vkui--size_border_radius_paper--regular);
  position: relative;
}

@media (--sizeX-regular) {
  .Group--mode-none.Group--sizeX-none {
    background: var(--vkui--color_background_content);
    border-radius: var(--vkui--size_border_radius_paper--regular);
    position: relative;
  }
}

.Group--mode-card::after {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  box-shadow: inset 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_separator_primary);
}

@media (--sizeX-regular) {
  .Group--mode-none::after {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
    box-shadow: inset 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_field_border_alpha);
  }
}

.Group--mode-plain + .Group__separator,
.Group--mode-plain + .Group__separator + .Group__separator {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (--sizeX-compact) {
  .Group--sizeX-none.Group--mode-none + .Group__separator,
  .Group--sizeX-none.Group--mode-none + .Group__separator + .Group__separator {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.Group:last-of-type ~ .Group__separator {
  display: none;
}

.Group:last-of-type ~ .Group__separator--force,
.Group--sizeX-regular.Group--mode-none:last-of-type + .Group__separator--spacing,
.Group--sizeX-regular.Group--mode-card:last-of-type + .Group__separator--spacing {
  display: block;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-card:last-of-type + .Group__separator--spacing,
  .Group--sizeX-none.Group--mode-none:last-of-type + .Group__separator--spacing {
    display: block;
  }
}

.Group__description {
  display: block;
  padding: 4px 16px 16px;
  color: var(--vkui--color_text_secondary);
}

.Group .Group,
.Group .Group + .Group__separator {
  padding-left: 0;
  padding-right: 0;
}

.Group .Group:first-of-type {
  padding-top: 0;
}

.Group .Group:last-of-type {
  padding-bottom: 0;
}

/**
 * iOS
 */
.Group--ios .Group__description {
  padding: 4px 12px 16px;
}

/*
 * CMP:
 * PanelHeader
 */
:global(.vkuiInternalPanelHeader--vkcom) ~ .Group:first-of-type,
:global(.vkuiInternalPanelHeader--vkcom) + * .Group:first-of-type {
  position: relative;
  top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
