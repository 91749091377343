.Removable__content {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
}

.Removable__action {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
}

/* Увеличиваем вес селектора */
.Removable__action.Removable__action {
  color: var(--vkui--color_icon_secondary);
}

.Removable--align-start .Removable__action {
  align-self: flex-start;
}

/**
 * iOS
 */
.Removable--ios {
  overflow: hidden;
}

.Removable__remove {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 15px;
  line-height: 45px;
  color: var(--vkui_internal--white);
  background-color: var(--vkui--color_background_negative);
  border: none;
  border-radius: 0;
}

.Removable__remove-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .Removable--ios .Removable__content {
    transition: transform 0.6s var(--vkui--animation_easing_platform);
  }
}

.Removable__toggle {
  display: block;
  width: 44px;
  height: 44px;
  margin: 0 2px;
  background: none;
}

.Removable__toggle-in {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
}

.Removable__toggle-in::after {
  content: '';
  width: 22px;
  height: 22px;
  background-color: var(--vkui--color_background_negative);
  border-radius: 50%;
}

.Removable__toggle-in::before {
  content: '';
  position: absolute;
  display: block;
  width: 12px;
  height: 2px;
  background-color: var(--vkui_internal--white);
}

.Removable--ios .Removable__content {
  padding-left: 0;
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
}

/**
 * CMP:
 * FormLayoutGroup
 * FormItem
 */
:global(.vkuiInternalFormItem--removable) .Removable__content,
:global(.vkuiInternalFormLayoutGroup--removable) .Removable__content {
  flex-wrap: wrap;
  align-items: flex-start;
}

.Removable__offset {
  display: none;
}

:global(.vkuiInternalFormItem--withTop .vkuiInternalFormItem__removable) ~ .Removable__offset,
:global(.vkuiInternalFormLayoutGroup--removable .vkuiInternalFormItem--withTop)
  ~ .Removable__offset {
  order: -1;
  display: block;
  width: 100%;
  height: calc(2px + var(--vkui--font_subhead--line_height--regular) + 8px);
}

:global(
    .vkuiInternalFormItem--sizeY-compact.vkuiInternalFormItem--withTop
      .vkuiInternalFormItem__removable
  )
  ~ .Removable__offset,
:global(
    .vkuiInternalFormLayoutGroup--sizeY-compact.vkuiInternalFormLayoutGroup--removable
      .vkuiInternalFormItem--withTop
  )
  ~ .Removable__offset {
  height: calc(2px + var(--vkui--font_subhead--line_height--compact) + 6px);
}

@media (--sizeY-compact) {
  :global(
      .vkuiInternalFormItem--sizeY-none.vkuiInternalFormItem--withTop
        .vkuiInternalFormItem__removable
    )
    ~ .Removable__offset,
  :global(
      .vkuiInternalFormLayoutGroup--sizeY-none.vkuiInternalFormLayoutGroup--removable
        .vkuiInternalFormItem--withTop
    )
    ~ .Removable__offset {
    height: calc(2px + var(--vkui--font_subhead--line_height--compact) + 6px);
  }
}
